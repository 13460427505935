<template>
<div class="form-container">
  <form @submit="addCompany" enctype="multipart/form-data">
    <!--<form action="upload.php" method="post" enctype="multipart/form-data">-->
    <div>
      <div>
        <label>Nazwa producenta lub imię i nazwisko</label><br/>
        <input type="text" v-model="company.prodName" />
      </div>
      <div>
        <label>Ulica, nr.domu</label>
        <input type="text" v-model="prodStreet" />
      </div>
      <div>
        <label>Kod pocztowy</label>
        <input
          type="text"
          v-model="prodZipCode"
          @blur="validateZipCode(this.prodZipCode)"
        />
      </div>
      <div class="error">
        <p>{{ error.zipcode }}</p>
      </div>
      <div>
        <label>Miasto</label>
        <input type="text" v-model="prodCity" />
      </div>
      <div>
        <label for="woj">Województwo</label>
        <select id="woj" v-model="company.woj">
            <option value="dolnośląskie">dolnośląskie</option>
            <option value="kujawsko-pomorskie">kujawsko-pomorskie</option>
            <option value="lubelskie">lubelskie</option>
            <option value="łódzkie">łódzkie</option>
            <option value="małopolskie">małopolskie</option>
            <option value="mazowieckie">mazowieckie</option>
            <option value="opolskie">opolskie</option>
            <option value="podkarpackie">podkarpackie</option>
            <option value="podlaskie">podlaskie</option>
            <option value="pomorskie">pomorskie</option>
            <option value="śląskie">śląskie</option>
            <option value="świętokrzyskie">świętokrzyskie</option>
            <option value="łódzkie">łódzkie</option>
            <option value="warmińsko-mazurskie">warmińsko-mazurskie</option>
            <option value="wielkopolskie">wielkopolskie</option>
            <option value="zachodniopomorskie">zachodniopomorskie</option>
        </select>
      </div>
      <div>
        <label>Adres mailowy</label>
        <input type="text" v-model="company.mail" @blur="validateEmail(this.company.mail)"/>
      </div>
        <div class="error">
        <p>{{ error.email }}</p>
      </div>
      <div>
        <label>Telefon komórkowy</label>
        <input type="text" v-model="company.tel1" @blur="validateMobilePhone(this.company.tel1)"/>
      </div>
        <div class="error">
        <p>{{ error.mobile }}</p>
      </div>
           <div>
        <label>Telefon komórkowy 2</label>
        <input type="text" v-model="company.tel3" @blur="validateMobilePhone1(this.company.tel3)"/>
      </div>
        <div class="error">
        <p>{{ error.mobile2 }}</p>
      </div>
        <div>
        <label>Telefon stacjonarny</label>
        <input type="text" v-model="company.tel2" @blur="validatePhone(this.company.tel2)"/>
      </div>
        <div class="error">
        <p>{{ error.phone }}</p>
      </div>
      <div>
        <label>Adres strony</label>
        <input type="text" v-model="company.webSite" @blur="validateWebSite(this.company.webSite)"/>
      </div>
      <div class="error">
        <p>{{ error.site }}</p>
      </div>
      <div>
        <label>Facebook</label>
        <input type="text" v-model="company.facebook" @blur="validateFacebook(this.company.facebook)"/>
      </div>
      <div class="error">
        <p>{{ error.facebook }}</p>
      </div>
      <div>
        <label>Instagram</label>
        <input type="text" v-model="company.instagram" @blur="validateInstagram(this.company.instagram)"/>
      </div>
      <div class="error">
        <p>{{ error.instagram }}</p>
      </div>
      <div>
        <label>Kategoria</label>
        <select v-model="chosenCategory">
          <option disabled value="">Wybierz</option>
          <option v-for="category in allCategories" :key="category.name">
            {{ category.name }}
          </option>
        </select>
      </div>
      <div v-if="this.chosenCategory !== ''">
          <div v-for="type in this.typesFromCategory.types" :key="type" class="check-box" >
          <label class="container">{{ type }}
              <input
            type="checkbox"
            id="subcategory"
            name="subcategory"
            :value="type"
          />
              <span class="checkmark"></span>
          </label><br/>
        </div>
      </div>
      <div class="desc-logo"><p>Wybierz logo</p></div>
      <div class="inputFile-container">
        <input type="file" v-on:change="handleFileUpload($event)" />
      </div>
      <div class="desc"><p>Wyślij i dodaj nową kategorię do tego samego klienta</p></div>
      <button @click="submitAddNewCategory">Nowa kategoria</button>
      <div class="desc"><p>Wyślij i zacznij wprowadzać nowego klienta</p></div>
      <input type="submit" value="Wyślij"/>
    </div>
    <div class="response-alert-OK"> {{ responseAlertOK }}</div>
    <div class="response-alert-error"> {{ responseAlertError }}</div>
  </form>
</div>
</template>
<script>
import axios from "axios";
import CompaniesDataService from "../GetCompanies.js";
import categories from "../categories.js";
export default {
  data() {
    return {
      company: {
        prodName: "",
        prodAdress: "",
        woj: "",
        mail: "",
        tel1: "",
        tel2: "",
        tel3: "",
        webSite: "",
        facebook: "",
        instagram: "",
        category: "",
        subcategories: {
          dzemy: false,
          powidła: false,
          konfitury: false,
          musy: false,
          inneDzemy: false,
          maki: false,
          kasze: false,
          przetowyZbozowe: false,
          miesoWieprzowe: false,
          miesoWolowe: false,
          miesoDrobiowe: false,
          miesoCielence: false,
          inneMieso: false,
          wedliny: false,
          wyrobyWedliniarskie: false,
        },
      },
      prodStreet: "",
      prodZipCode: "",
      prodCity: "",
      file: "",
      allCategories: categories,
      chosenCategory: "",
      typesFromCategory: [],
      error: {
        zipcode: "",
        email: "",
        mobile: "",
        phone: "",
        mobile2: "",
        facebook: "",
        instagram: ""
      },
      sendForm: true,
      responseAlertOK: "",
      responseAlertError: ""
    };
  },
  methods: {
    addCompany() {
      this.company.prodAdress =
        this.prodStreet + ", " + this.prodZipCode + " " + this.prodCity;
      console.log(this.company.prodAdress);
      if (this.sendForm) {
        var checkedSubcategories = [];
        event.preventDefault();
        let checkboxes = document.getElementsByName("subcategory");
        //let checkedSubcategories = [];
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            checkedSubcategories.push(checkboxes[i].value);
            checkedSubcategories.toString();
          }
        }
        this.company.subcategories.dzemy =
          checkedSubcategories.includes("dżemy");
        this.company.subcategories.powidla =
          checkedSubcategories.includes("powidła");
        this.company.subcategories.konfitury =
          checkedSubcategories.includes("konfitury");
        this.company.subcategories.musy = checkedSubcategories.includes("musy");
        this.company.subcategories.inneDzemy =
          checkedSubcategories.includes("inne");
        this.company.subcategories.maki = checkedSubcategories.includes("mąki");
        this.company.subcategories.kasze =
          checkedSubcategories.includes("kasze");
        this.company.subcategories.przetowyZbozowe =
          checkedSubcategories.includes("przetwory zbożowe");
        this.company.subcategories.miesoWieprzowe =
          checkedSubcategories.includes("mięso wieprzowe");
        this.company.subcategories.miesoWolowe =
          checkedSubcategories.includes("mięso wołowe");
        this.company.subcategories.miesoDrobiowe =
          checkedSubcategories.includes("mięso drobiowe");
        this.company.subcategories.miesoCielence =
          checkedSubcategories.includes("cielęcina");
        this.company.subcategories.inneMieso =
          checkedSubcategories.includes("inne mięso");
        this.company.subcategories.wedliny =
          checkedSubcategories.includes("wędliny");
        this.company.subcategories.wyrobyWedliniarskie =
          checkedSubcategories.includes("wyroby wędliniarskie");

        const formData = new FormData();
        formData.append("woj", this.company.woj);
        formData.append("prodName", this.company.prodName);
        formData.append("prodAdress", this.company.prodAdress);
        formData.append("mail", this.company.mail);
        formData.append("tel1", this.company.tel1);
        formData.append("tel2", this.company.tel2);
        formData.append("tel3", this.company.tel3);
        formData.append("webSite", this.company.webSite);
        formData.append("facebook", this.company.facebook);
        formData.append("instagram", this.company.instagram);
        formData.append("category", this.chosenCategory);
        formData.append("dzemy", this.company.subcategories.dzemy);
        formData.append("powidla", this.company.subcategories.powidla);
        formData.append("konfitury", this.company.subcategories.konfitury);
        formData.append("musy", this.company.subcategories.musy);
        formData.append("inneDzemy", this.company.subcategories.inneDzemy);
        formData.append("maki", this.company.subcategories.maki);
        formData.append("kasze", this.company.subcategories.kasze);
        formData.append(
          "przetowyZbozowe",
          this.company.subcategories.przetowyZbozowe
        );
        formData.append(
          "miesoWieprzowe",
          this.company.subcategories.miesoWieprzowe
        );
        formData.append("miesoWolowe", this.company.subcategories.miesoWolowe);
        formData.append(
          "miesoDrobiowe",
          this.company.subcategories.miesoDrobiowe
        );
        formData.append(
          "miesoCielence",
          this.company.subcategories.miesoCielence
        );
        formData.append("inneMieso", this.company.subcategories.inneMieso);
        formData.append("wedliny", this.company.subcategories.wedliny);
        formData.append(
          "wyrobyWedliniarskie",
          this.company.subcategories.wyrobyWedliniarskie
        );
        formData.append("subcat", checkedSubcategories);
        formData.append("file", this.file, this.file.name);

        console.log(formData.getAll("dzemy"));
        //CompaniesDataService.createCompany(formData);

        axios.post("../upload.php", formData, {}).then((res) => {
          console.log(res);
          if(res.status !== 200) {
          this.responseAlertError = "Coś poszło nie tak. Sprawdź rozmiar zdjęcia i poprawność danych.";
            setTimeout(()=> {
              this.responseAlertError = ""
            }, 3000);
          } else if(res.status == 200) {
            this.responseAlertOK = "Zapisano w bazie"
            setTimeout(()=> {
              this.responseAlertOK = ""
            }, 4000
            )
          }
        });
      }
    },
    submitAddNewCategory() {
       this.company.prodAdress =
        this.prodStreet + ", " + this.prodZipCode + " " + this.prodCity;
      console.log(this.company.prodAdress);
      if (this.sendForm) {
        var checkedSubcategories = [];
        event.preventDefault();
        let checkboxes = document.getElementsByName("subcategory");
        //let checkedSubcategories = [];
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            checkedSubcategories.push(checkboxes[i].value);
            checkedSubcategories.toString();
          }
        }
        const formData = new FormData();
        formData.append("woj", this.company.woj);
        formData.append("prodName", this.company.prodName);
        formData.append("prodAdress", this.company.prodAdress);
        formData.append("mail", this.company.mail);
        formData.append("tel1", this.company.tel1);
        formData.append("tel2", this.company.tel2);
        formData.append("tel3", this.company.tel3);
        formData.append("webSite", this.company.webSite);
        formData.append("facebook", this.company.facebook);
        formData.append("instagram", this.company.instagram);
        formData.append("category", this.chosenCategory);
        formData.append("subcat", checkedSubcategories);
        formData.append("file", this.file, this.file.name);

        console.log(formData.getAll("dzemy"));
        //CompaniesDataService.createCompany(formData);
        this.chosenCategory = '';
        axios.post("../upload.php", formData, {}).then((res) => {
          console.log(res);
          if(res.status !== 200) {
          this.responseAlertError = "Coś poszło nie tak. Sprawdź rozmiar zdjęcia i poprawność danych.";
            setTimeout(()=> {
              this.responseAlertError = ""
            }, 3000);
          } else if(res.status == 200) {
            this.responseAlertOK = "Zapisano w bazie"
            setTimeout(()=> {
              this.responseAlertOK = ""
            }, 4000
            )
          }
        });
      }
    },
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      CompaniesDataService.uploadLogo(formData);
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    validateZipCode(code) {
      const regex = /^[0-9]{2}-[0-9]{3}$/;
      if (!regex.test(code)) {
        this.error.zipcode = "Wprowadź poprawny kod pocztowy.";
        this.sendForm = false;
      } else {
        this.error.zipcode = "";
        this.sendForm  = true;
      }
    },
    validateEmail(email) {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
         if (!regex.test(email)) {
                this.error.email = "Wpisz poprawny adres email.";
                this.sendForm = false;
            } else {
                this.error.email = " ";
                this.sendForm  = true;
            }
        },
    validateMobilePhone(phone) {
         const regex = /^\d{3} \d{3} \d{3}$/;
         if (!regex.test(phone)) {
                this.error.mobile = "Wpisz numer telefonu komórkowego w formacie 'XXX XXX XXX'.";
                this.sendForm = false;
            } else {
                this.error.mobile = " ";
                this.sendForm  = true;
            }
    },
        validateMobilePhone1(phone) {
         const regex = /^\d{3} \d{3} \d{3}$/;
         if (!regex.test(phone)) {
                this.error.mobile2 = "Wpisz numer telefonu komórkowego w formacie 'XXX XXX XXX'.";
                this.sendForm = false;
            } else {
                this.error.mobile2 = " ";
                this.sendForm  = true;
            }
    },
    validatePhone(phone1) {
            const regex = /^\+?([0-9]{2})[-. ]?([0-9]{3})[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/;
         if (!regex.test(phone1)) {
                this.error.phone = "Wpisz numer telefonu w formacie 'XX XXX XX XX'.";
                this.sendForm = false;
            } else {
                this.error.phone = " ";
                this.sendForm  = true;
            }
    },
        validateWebSite(site) {
            const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
         if (!regex.test(site)) {
                this.error.site = "Wpisz poprawny adres strony.";
                this.sendForm = false;
            } else {
                this.error.site = " ";
                this.sendForm  = true;
            }
    },
      validateFacebook(facebookLink) {
            const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
         if (!regex.test(facebookLink)) {
                this.error.facebook = "Wpisz poprawny adres strony facebook.";
                this.sendForm = false;
            } else {
                this.error.facebook = " ";
                this.sendForm  = true;
            }
    },
          validateInstagram(instaLink) {
            const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
         if (!regex.test(instaLink)) {
                this.error.instagram = "Wpisz poprawny adres strony instagram.";
                this.sendForm = false;
            } else {
                this.error.instagram = " ";
                this.sendForm  = true;
            }
    }
  },
  watch: {
    chosenCategory() {
      let category = this.chosenCategory;
      let typesArray = this.allCategories.find(
        (option) => option.name == category
      );
      this.typesFromCategory = typesArray;
    },
  },
};
</script>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
form {
  margin: 100px;
  width: 600px;
}
div {
  margin: 25px;
}
label {
  font-size: 16px;
  float:left;
}
input {
  width: 100%;
  font-size: 19px;
  padding: 5px;
}
input[type='checkbox'] {
  width: auto;
  margin: 0;
  padding:0;
}
option {
  width: 100%;
  font-size: 19px;
  padding: 5px;
}
select {
  width: 100%;
  padding: 5px;
  font-size: 19px;
}
.error {
  color: red;
}
.response-alert-OK {
  color: green;
  font-size: 24px;
}
.response-alert-error {
  color: red;
  font-size: 24px;
}
input[type="submit"], button {
  font-size: 19px;
  background-color: #3084E2;
  padding: 10px;
  color: white;
  border: 1px solid #3084E2;
  border-radius: 15px;
  width: 100%;
  margin: 0 30px 15px 20px;
}
input[type="submit"]:hover, button:hover {
  background-color: #164b88;
  cursor: pointer;
}
.inputFile-container {
  margin: 0 20px;
}
.desc {
  margin: 0;
}
.desc-logo {
  float: left;
   margin: 0 20px;;
   font-weight: 600;
}
.desc p{
  margin: 3px;
}

/*styling checkboxes*/

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-box {
  width: 100%;
}
</style>
