import axios from 'axios'

const USER_API_URL = ''

class CompaniesDataService {

    retrieveAllCompanies() {

        return axios.get(`${USER_API_URL}/fetch.php`);
    }
    createCompany(user) {
        console.log(user);
        return axios.post(`${USER_API_URL}/upload.php`, user)
        //return axios.post(`${USER_API_URL}/create.php`, user);
    }
    uploadLogo(logo) {
        console.log(logo);
        return axios.post(`${USER_API_URL}/upload.php`, logo, {headers: {
            'Content-Type': 'multipart/form-data'
        }}).then(function(){
            console.log('SUCCESS!!');
          })
          .catch(function(){
            console.log('FAILURE!!');
          })
    }
    retriveAllLogos() {
        return axios.get(`${USER_API_URL}/fetch.php`)
        // generator URI PHP




        // axios({
        //     url: 'https://codewithblonde.com/photos', //your url
        //     method: 'GET',
        //     responseType: 'blob', // important
        // })
        // .then(res => {
        //   this.photos = res.data;
        // })
    }


}

export default new CompaniesDataService()